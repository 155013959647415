import { ref, computed, watch } from '@vue/composition-api'
import store from '@/store'
import { convertInputsToKeyValuePair } from '@/helpers/objectHelper'

export default function useNewConfirmationHandler(props, clearForm, emit) {
  // ------------------------------------------------
  // eventLocal
  // ------------------------------------------------
  const eventLocal = ref(JSON.parse(JSON.stringify(props.event.value)))
  const resetEventLocal = () => {
    eventLocal.value = JSON.parse(JSON.stringify(props.event.value))
  }
  watch(props.event, () => {
    resetEventLocal()
  })

  const onSubmit = () => {
    const eventData = JSON.parse(JSON.stringify(eventLocal))
    // * If event has id => Edit Event
    // Emit event for add/update event
    if (props.event.value.id) emit('update-event', eventData.value)
    else emit('add-event', eventData.value)

    // Close sidebar
    emit('update:is-new-process-handler-sidebar-active', false)
  }

  return {
    eventLocal,
    resetEventLocal,

    // UI
    onSubmit,
  }
}
