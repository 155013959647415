<template>
  <div>
    <b-sidebar
      id="sidebar-add-new-event"
      sidebar-class="sidebar-lg"
      :visible="isNewConfirmationHandlerSidebarActive"
      bg-variant="white"
      shadow
      backdrop
      no-header
      right
      @change="(val) => $emit('update:is-new-process-handler-sidebar-active', val)"
    >
      <template #default="{ hide }">
        <!-- Header -->
        <div
          class="
            d-flex
            justify-content-between
            align-items-center
            content-sidebar-header
            px-2
            py-1
          "
        >
          <h5 class="mb-0">{{ $t('newConfirmation') }}</h5>
          <div>
            <feather-icon
              class="ml-1 cursor-pointer"
              icon="XIcon"
              size="16"
              @click="hide"
            />
          </div>
        </div>

        <!-- Body -->
        <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
          <!-- Form -->
          <b-form
            class="p-2"
          >
            <!-- Worker -->
            <validation-provider
              #default="validationContext"
              :name="$t('label.trabajador')"
              rules="required"
            >
              <b-form-group :label="$t('label.trabajador')" label-for="confirmation-worker">
                <v-select
                  v-model="eventLocal.worker"
                  :options="workersSupervised"
                  label="title"
                  :reduce="(worker) => worker.value"
                  :disabled="true"
                />
                <b-form-invalid-feedback :state="getValidationState(validationContext)">
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <!-- Process -->
            <validation-provider
              #default="validationContext"
              :name="$t('label.process')"
              rules="required"
            >
              <b-form-group :label="$t('label.process')" label-for="confirmation-process">
                <v-select
                  v-model="eventLocal.process"
                  :options="processes"
                  label="title"
                  :reduce="(process) => process.value"
                />
                <b-form-invalid-feedback  :state="getValidationState(validationContext)">
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
            
            <!-- Start confirmation -->

            <div class="d-flex mt-2">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="mb-2"
                :disabled="!eventLocal.process"
                @click="
                  $router.push({
                    path: `/habit/confirmation/new/${eventLocal.process}/${eventLocal.worker.value}`
                  })
                "
              >
                {{ $t('message.start_confirmation') }}
              </b-button>
            </div>
          </b-form>
        </validation-observer>
      </template>
    </b-sidebar>
  </div>
</template>

<script>
import {BSidebar, BForm, BFormGroup, BFormInput, BFormCheckbox, BAvatar, BFormTextarea, BButton, BFormInvalidFeedback, BModal, VBModal } from "bootstrap-vue";
import vSelect from "vue-select";
import flatPickr from "vue-flatpickr-component";
import Ripple from "vue-ripple-directive";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, email, url } from "@validations";
import formValidation from "@core/comp-functions/forms/form-validation";
import { ref, toRefs, onMounted } from "@vue/composition-api";
import useNewConfirmationHandler from "./useNewConfirmationHandler";
import useCommon from "@/views/organization/useCommon";

export default {
  components: {
    BButton,
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BFormTextarea,
    BAvatar,
    vSelect,
    flatPickr,
    ValidationProvider,
    BFormInvalidFeedback,
    ValidationObserver,
    BModal,
  },
  directives: {
    Ripple,
    'b-modal': VBModal,
  },
  model: {
    prop: "isNewConfirmationHandlerSidebarActive",
    event: "update:is-new-process-handler-sidebar-active",
  },
  props: {
    isNewConfirmationHandlerSidebarActive: {
      type: Boolean,
      required: true,
    },
    event: {
      type: Object,
      required: true,
    },
    clearEventData: {
      type: Function,
      required: true,
    },
    isOnline: {
      type: Boolean,
      require: true,
    },
  },
  data() {
    return {
      required,
      email,
      url,
    };
  },
  setup(props, { emit }) {
    const {
      getWorkersSupervisedForDropDown,
      workersSupervised,
      getProcessesForDropDown,
      processes,
    } = useCommon();

    onMounted(async () => {
      getProcessesForDropDown();
      getWorkersSupervisedForDropDown();
    });

    const clearFormData = ref(null);

    const {
      eventLocal,
      resetEventLocal,

      // UI
      onSubmit,
    } = useNewConfirmationHandler(toRefs(props), clearFormData, emit);

    const { refFormObserver, getValidationState, resetForm, clearForm } =
      formValidation(resetEventLocal, props.clearEventData);

    clearFormData.value = clearForm;

    return {
      // Add New Event
      eventLocal,
      onSubmit,

      // Form Validation
      resetForm,
      refFormObserver,
      getValidationState,

      workersSupervised,
      processes,
    };
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>