<template lang="pug">
.dashboard-wrapper
    b-row
      b-col
        b-card(no-body,
        :class="[ 'pt-2 px-2', isDemoUser ? 'mt-3' : (isImageAvailable ? 'mt-3 mt-md-0' : 'mt-0')]"
        )
          filters(
            :locationFilterShow="true"
            :roleFilterShow="true"
            :workerFilterShow="true"
            :dateRangeFilterShow="true"
            @changeFilter="updateFilter"
          )
  
    b-row(v-if="isLoading")
      b-col
        b-card(class="py-5")
          loading(
            :active="true" 
            :is-full-page="false"
            :color="colors.primary"
          )
  
    div(v-else)
      b-row(v-if="!attendeeGroup.length")
        b-col
          b-card(class="text-center")
            h5(class="mb-0")
              | {{ $t('message.no_workers_found') }}
  
      div(v-else)
        b-row
          b-col(sm="3")
            statistic-card-vertical-total-workers(:statistic="totalEvents")
          b-col(sm="3")
            statistic-card-vertical-done-cero(:statistic="eventsDoneCero")
          b-col(sm="3")
            statistic-card-vertical-events-by-employees(:statistic="eventsByEmployees")
          b-col(sm="3")
            statistic-card-vertical-done(:statistic="eventsDone")
            
        b-card
          b-table-simple(hover, small, caption-top, responsive :key="tableKey")
            b-thead(head-variant="light")
              b-tr
                th {{ $t('message.tableHeader.worker') }}
                th {{ $t('message.tableHeader.observer') }}
                th {{ $t('message.tableHeader.process') }}
                th {{ $t('message.tableHeader.complete_status') }}
                th {{ $t('message.tableHeader.action') }}
              b-tr(v-for="(worker, index) in attendeeGroup", :key="index", @click="worker.events && worker.events.length > 1 && showConfirmationData(worker.events, worker.name, worker.fixedIndex)")
                b-td(:class="!worker._id ? ['worker-cell', 'worker-name'] : {}")
                  | {{ worker._id ? worker.name : worker.date }}
                b-td(:class="{ 'worker-cell': !worker._id, 'columnWidth': true  }")
                  | {{ worker.numEventsDone === 0 ? '' : worker.numEventsDone === 1 ? worker.events[0].confirmationData && worker.events[0].confirmationData.supervisorName || '' : null }}
                  feather-icon(v-if="worker.numEventsDone > 1" variant="outline-light" icon= 'MenuIcon' class="featherIcon")
                  | {{ worker.supervisorName }}
                b-td(:class="{ 'worker-cell': !worker._id, 'columnWidth': true  }")
                  | {{ worker.numEventsDone === 0 ? '' : worker.numEventsDone === 1 ? worker.events[0].confirmationData && worker.events[0].confirmationData.processName || '' : null }}
                  feather-icon(v-if="worker.numEventsDone > 1" variant="outline-light" icon= 'MenuIcon' class="featherIcon")
                  | {{ worker.processName }}
                b-td(:class="[!worker._id && 'worker-cell', 'columnWidth', worker.numEventsDone <= 0 ? 'text-danger' : 'text-success']") {{ worker.numEventsDone }}
                b-td(:class="{ 'worker-cell': !worker._id, 'columnWidth': true }")
                  user-button(v-if="worker._id" @clicked="worker._id && router.push({name: 'apps-users-view', params: { id: worker._id }})")
                  navigation-button(v-if="worker._id" @clicked="openEventHandlerSidebar(worker)")
                  view-button(v-if="worker.event_id || worker.numEventsDone === 1" variant="flat-success" @clicked="router.push({ name: 'habit-confirmation-view', params: { id: worker.event_id || worker.events[0].confirmationData._id } })")
          b-col.d-flex.justify-content-between.flex-wrap(v-if='isOnline' cols='12')
            .mb-1
              b-form-group.mb-0
                label.d-inline-block.text-sm-left.mr-50 {{ $t('message.per_page') }}
                b-form-select#perPageSelect.w-50(
                  v-model='perPage' 
                  size='sm'
                  :options='[5,10,15,20,25,30,40,50,100]')
            .mb-1
              
              span.text-muted {{ showingMessage }}
            b-pagination(
              v-model='currentPage' 
              :total-rows='totalEvents' 
              :per-page='perPage' 
              first-number='' 
              last-number='' 
              prev-class='prev-item' 
              next-class='next-item')
            
    
    //- Sidebar Overlay: Event Handler
    div(
      class="body-content-overlay"
      :class="{'show': isCalendarOverlaySidebarActive}"
      @click="isCalendarOverlaySidebarActive = false"
    )
    new-confirmation-handler(
      v-model="isNewConfirmationHandlerSidebarActive"
      :event="event"
      :clear-event-data="clearEventData"
      :isOnline="isOnline"
    )
</template>
  
<script>
import { onMounted, ref, computed, onUnmounted, watch } from "@vue/composition-api/dist/vue-composition-api"
import { useRouter } from '@core/utils/utils'
import store from "@/store"
import { BTableSimple, BThead, BTr, BTd, BCard, BPagination } from "bootstrap-vue"
import useCommonDashboards from '@/views/habit/useCommonDashboards'
import StatisticCardVerticalTotalWorkers from './charts/StatisticCardVerticalTotalWorkers.vue'
import StatisticCardVerticalEventsByEmployees from './charts/StatisticCardVerticalEventsByEmployees.vue'
import StatisticCardVerticalDoneCero from './charts/StatisticCardVerticalDoneCero.vue'
import StatisticCardVerticalDone from './charts/StatisticCardVerticalDone.vue'
import Filters from "@/views/organization/Filters.vue"
import i18n from '@/libs/i18n'
import ViewButton from '@/views/components/Shared/Buttons/ViewButton.vue'
import NavigationButton from '@/views/components/Shared/Buttons/NavigationButton.vue'
import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'
import NewConfirmationHandler from '@/views/apps/pc-team/new-confirmation-handler/NewConfirmationHandler.vue'
import calendarStoreModule from '@/views/apps/calendar/calendarStoreModule'
import useCommon from '@/views/organization/useCommon'
import UserButton from '@/views/components/Shared/Buttons/UserButton.vue'
import { colors } from '@/constants'
import realmConnection from '@/views/habit/realm'

export default {
  components: {
    BTableSimple,
    BThead,
    BTr,
    BTd,
    BCard,
    BPagination,
    StatisticCardVerticalTotalWorkers,
    StatisticCardVerticalDone,
    StatisticCardVerticalDoneCero,
    StatisticCardVerticalEventsByEmployees,
    Filters,
    ViewButton,
    NavigationButton,
    Loading,
    NewConfirmationHandler,
    UserButton
  },
  setup() {
    const userData = store.state?.userStore?.userData;
    const userRole = userData.role;
    const client_id = userRole !== "admin" ? userData.client.$oid : null;
    const userId = userData.worker_id != null ? userData.worker_id.$oid : null

    const { handleError } = useCommon()
    const { getDatesFromRange } = useCommonDashboards()
    const { router } = useRouter()
    const { invokeFunction } = realmConnection()
    const { default_language } = JSON.parse(localStorage.getItem('clientData') || '{}')
    const events = ref([]);
    const attendeeGroup = ref([])
    const eventsDone = ref(0)
    const eventsPending = ref(0)
    const eventsTotal = ref(0)
    const eventsDoneCero = ref(0)
    const eventsByEmployees = ref("")
    const locationFilter = ref([])
    const roleFilter = ref([])
    const workerFilter = ref([])
    const zoneFilter = ref([]);
    const agencyFilter = ref([])
    const now = new Date()
    const monthFilter = ref(now.getMonth())
    const yearFilter = ref(now.getFullYear())
    const isLoading = ref(true)
    const zoneAgencyFilter = localStorage.getItem("clientData")
      ? JSON.parse(localStorage.getItem("clientData")).zone_agency_filter
      : false
    const isOnline = computed(() => store.state.app.isOnline)
    const isNewConfirmationHandlerSidebarActive = ref(false)
    const isCalendarOverlaySidebarActive = ref(false)
    const CALENDAR_APP_STORE_MODULE_NAME = 'calendar'
    const dataEvents = ref([])
    const currentMonth = now.getMonth()
    const currentYear = now.getFullYear()
    const lastDayOfMonthDate = new Date(currentYear, currentMonth + 1, 0)
    const lastDayOfMonthNumber = lastDayOfMonthDate.getDate()
    const dateRangeFilter = ref(`01-${currentMonth + 1}-${currentYear} to ${lastDayOfMonthNumber}-${currentMonth + 1}-${currentYear}`)

    const totalEvents = ref(0)
    const perPage = ref(10)
    const currentPage = ref(1)
    const searchQuery = ref('')
    const statusFilter = ref(null)
    const activeIndex = ref(-1)
    const tableKey = ref(1)

    const showingMessage = computed(() => {
      const from = ((currentPage.value * perPage.value) - perPage.value) + (dataEvents.value.length ? 1 : 0)
      const to = dataEvents.value.length + ((currentPage.value * perPage.value) - perPage.value)

      return i18n.tc('message.paginationText', 0, { from: from, to: to, total: totalEvents.value })
    })
    const { project} = JSON.parse(localStorage.getItem('userData') || '{}')
    const isDemoUser = project?.startsWith("demoUser")

    const clientDataName = JSON.parse(localStorage.getItem("clientData") || '{}').name
    const imageName = clientDataName?.toLowerCase() + '.png'
    
    const isImageAvailable = computed(() => {
      try {
        require(`@/assets/images/clients_logo/${imageName}`)
        return true
      } catch (e) {
        return false
      }
    })

    watch([currentPage, perPage, statusFilter], () => {
      listWorkersSupervisedWithEvents()
    })

    watch([searchQuery], () => {
      currentPage.value = 1
      listWorkersSupervisedWithEvents()
    })

    watch(attendeeGroup, () => {
      tableKey.value += 1
    })

    // Register module
    if (!store.hasModule(CALENDAR_APP_STORE_MODULE_NAME)) store.registerModule(CALENDAR_APP_STORE_MODULE_NAME, calendarStoreModule)

    // Add event listener for connectivity status detection
    onMounted(() => {
      listWorkersSupervisedWithEvents()
    })

    // UnRegister and remove event listeners on leave
    onUnmounted(() => {
      if (store.hasModule(CALENDAR_APP_STORE_MODULE_NAME)) store.unregisterModule(CALENDAR_APP_STORE_MODULE_NAME)
    });

    const blankEvent = {
      worker: '',
      process: '',
    }
    const event = ref(JSON.parse(JSON.stringify(blankEvent)))
    const clearEventData = () => {
      event.value = JSON.parse(JSON.stringify(blankEvent))
    }

    async function listWorkersSupervisedWithEvents() {

      const [startFilter, endFilter] = getDatesFromRange(dateRangeFilter.value)
      
      try {
        const input = {
          limit: perPage.value,
          total: currentPage.value === 1,
          offset: (currentPage.value * perPage.value) - perPage.value,
          search: searchQuery.value,
          userId: userId,
          locations: locationFilter.value,
          roles: roleFilter.value,
          workers: workerFilter.value,
          privileges: userRole,
          client_id: client_id,
          date_gte: startFilter,
          date_lt: endFilter
        }
      
        const items = await invokeFunction({ name: 'eventsForSupervisedWorkers', arg: input })

        const dataQuery = items?.events
        const insideData = dataQuery[0]?.eventsData || []
        const total = dataQuery[0]?.totalEmployees
        const employeesWithEvents = dataQuery[0]?.totalNumEmployeesWithEvents
        const doneCero = dataQuery[0]?.totalNumEventsDoneCero
        const totalEventsDone = dataQuery[0]?.totalNumEventsDone
        dataEvents.value = insideData
        parseEventData(insideData, total, employeesWithEvents, doneCero, totalEventsDone)
      } catch (error) {
        console.log(error)
        handleError({ error, defaultMessage: i18n.t('message.err_confirmation_list') })
      } finally {
        isLoading.value = false
      }
    }

    function parseEventData(insideData, total, employeesWithEvents, doneCero, totalEventsDone) {
      if (currentPage.value === 1) totalEvents.value = total
      eventsDoneCero.value = doneCero
      eventsByEmployees.value = eventsByEmployeesTransform(employeesWithEvents,total)
      eventsDone.value = totalEventsDone

      insideData.forEach((e, index) => {
        e.fixedIndex = index
        e.events = e.events.filter(ev => ev.confirmationData !== null)
      })
      attendeeGroup.value = insideData
    }
    
    function eventsByEmployeesTransform (employeesWithEventsDone, employees){
      if(employees <= 0) return "0%"
      const eventsEmployees = Math.round((employeesWithEventsDone/employees)*100)
      return `${eventsEmployees}%`
    }

    const openEventHandlerSidebar = (workerData) => {
      event.value = { worker: { title: workerData.name, value: workerData._id }, process: '' }
      isNewConfirmationHandlerSidebarActive.value = true
    }

    function updateFilter(data) {
      locationFilter.value = data.locationFilter;
      roleFilter.value = data.roleFilter;
      workerFilter.value = data.workerFilter;
      monthFilter.value = data.monthFilter;
      yearFilter.value = data.yearFilter;
      dateRangeFilter.value = data.dateRangeFilter;
      zoneFilter.value = data.zoneFilter;
      agencyFilter.value = data.agencyFilter;

      listWorkersSupervisedWithEvents();
    }

    const parseAttendeeGroup = (events, name, fixedIndex, action) => {
      if (action.includes('remove')) {
        for (let i = attendeeGroup.value.length - 1; i >= 0; i--) {    
          !attendeeGroup.value[i]._id && attendeeGroup.value.splice(i, 1)
        }
        activeIndex.value = -1   
      }
      if (action.includes('add')) {
        for (const e of events) {
          if (!e.confirmationData?.processName) continue
          const date = e.confirmationData.date
          attendeeGroup.value.splice(fixedIndex + 1, 0, {
            name,
            processName: e.confirmationData.processName,
            supervisorName: e.confirmationData?.supervisorName,
            date: date?.toLocaleDateString(`${default_language || 'en'}-US`),
            event_id: e.confirmationData?._id
          })
        }
        activeIndex.value = fixedIndex
      }
    }

    const showConfirmationData = (events, name, fixedIndex) => {
      const action = activeIndex.value === fixedIndex ? 'remove' : activeIndex.value === -1 ? 'add' : 'remove-add'
      parseAttendeeGroup(events, name, fixedIndex, action)
    }

      return {
      events,
      eventsDone,
      eventsPending,
      eventsTotal,
      updateFilter,
      userRole,
      isLoading,
      zoneAgencyFilter,
      router,
      isOnline,
      isCalendarOverlaySidebarActive,
      event,
      clearEventData,
      isNewConfirmationHandlerSidebarActive,
      openEventHandlerSidebar,
      attendeeGroup,
      eventsDoneCero,
      eventsByEmployees,
      showConfirmationData,
      tableKey,
      isImageAvailable,
      isDemoUser,

      // Pagination
      totalEvents,
      perPage,
      currentPage,
      searchQuery,
      statusFilter,
      showingMessage,
      colors
    };
  },
};
</script>
  
<style lang="scss" scoped>

.worker-cell {
  border: none;
  background-color: #f3f2f7;
  animation: fadeInOpacity 1s ease, fadeInTranslate 0.5s ease; 
}

.worker-name {
  color: #b5b5b7;
}

.columnWidth {
  width: 12rem
}

.featherIcon {
  color: #aaaaaa;
  cursor: pointer;
  width: 1.2rem;
  height: 1.2rem;
  margin-left: 2rem;
}

@keyframes fadeInOpacity {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeInTranslate {
  from {
    transform: translateY(-2rem);
  }
  to {
    transform: translateY(0);
  }
}
</style>
